<template>
    <div class="main-wrapper">
      <router-view></router-view>
    </div>
</template>
<script>
// import RestApi, { apiBaseURL } from '@/config'

export default {
  name: 'AuthLayout',
  data () {
    return {
      closeSidebar: false
    }
  },
  created () {
    // this.getSiteInoData()
  },
  components: {
  },
  methods: {
    // async getSiteInoData () {
    //   this.loading = true
    //   const result = await RestApi.getData(apiBaseURL, 'admin/ajax/get_active_site_info_data')
    //   this.loading = false
    //   if (result.success) {
    //     this.$store.dispatch('setSiteInfo', result.data)
    //   }
    // }
  }
}
</script>
